body {
  margin: 0;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-size: 80px;
  font-weight: bold;
}
h2 {
  font-size: 40px;
}

h3 {
  margin: 0;
}
