$primary-color: #101820;
$light-background: #f2aa4c;

body {
  font-size: 16px;
}

h1 {
  font-size: 60px;
}

.cabecera {
  padding: 0 20px;
}

.portada {
}

.mosaicoMobile {
  color: $primary-color;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  background-color: $light-background;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    > h2 {
      text-align: center;
      margin: 10px;
    }
    > div {
      margin: 0 10px;
    }
  }

  .imagenWeb {
    width: 100%;
  }
}

.textoMobile {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.imagenesMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: linear-gradient(to bottom, $primary-color 50%, transparent 50%);
  margin-top: 30px;

  h2 {
    color: $light-background;
    margin: 25px;
  }

  .grid {
    width: 90%;
    margin: 0 5% 5% 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .item {
    width: 100%;
    height: 13vh;
    cursor: pointer;
    object-fit: cover;
  }

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  .overlay-content img {
    max-width: 80vw;
    max-height: 80vh;
  }
}

.contactoMobile {
  background-color: $light-background;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $primary-color;

  h2 {
    margin: 25px;
  }

  p {
    margin: 5px;
  }

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .titulo {
    display: flex;
    align-items: center;
  }

  .contenido {
    display: flex;
    flex-direction: column;
    align-items: center;

    > a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .campos {
    display: flex;
    align-items: center;
    flex-direction: column;

    > div {
      width: 100%;
    }

    > button {
      margin-top: 10px;
      color: $light-background;
    }
  }
}

.pieMobile {
}
